import React, { forwardRef } from 'react';
import styled from 'styled-components';

const ImageRoot = styled.img`
  height: auto;
  width: 100%;
  border-radius: 5px;
`;

const Image = forwardRef(({ src, ...rest }, ref) => {
  return (
    <ImageRoot ref={ref} src={src} {...rest} />
  );
});

export default Image;

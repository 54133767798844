import MainPageConstants from "../constants/MainPageConstants";

const initialState = {
    data: {
        title: '',
    }
}

function mainPageReducer(state = initialState, action) {
    switch (action.type) {
        case MainPageConstants.Actions.FETCH_DATA:
            return {
                ...state,
                data: action.data,
            }
        default:
            return state;
    }
}

export default mainPageReducer;
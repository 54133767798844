import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';


const BoxRoot = styled.div(({ grow }) => css`
  ${grow &&
    css`
      flex: 1;
  `};

  padding-bottom: 20px;
`);

const Box = forwardRef(({ children, ...rest }, ref) => {
  return (
    <BoxRoot ref={ref} {...rest}>
      {children}
    </BoxRoot>
  );
});

export default Box;

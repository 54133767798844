import React from 'react';
import InputMask from 'react-input-mask';

import './style/style.less';

const PhoneInput = props => {

  return <InputMask
    type="tel"
    className="kameta-phone-input"
    mask="+7\ (999) 999 99 99"
    maskChar="_"
    alwaysShowMask
    {...props.field}
  />

}

export default PhoneInput;
import React from 'react';
import InputField from '@/uikit/form';

const TextInput = props => {
  return <InputField
    type="text"
    {...props}
  />
}

export default TextInput;
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const MarkRoot = styled.div`
  background-color: ${props => props.theme.colorBook.colorMain};
  color: white;
  display: inline;
  padding: 5px;
  border-radius: 5px;
  
  svg {
    height: 30px;
    width: 30px;
  }
`;

const Mark = forwardRef(({ children, ...rest }, ref) => {
  return (
    <div>
      <MarkRoot ref={ref} {...rest}>
        {children}
      </MarkRoot>
    </div>
  );
});

export default Mark;

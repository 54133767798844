import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Icon from '@/uikit/icon';
import RoundContainer from '@/uikit/icon/RoundContainer';
import Stack from '@/uikit/stack';
import Title from '@/uikit/title/Title';

const ColorTitle = styled(Title)`
  color: ${props => props.theme.colorBook.colorMain};
`;

const TitleWithIcon = forwardRef(({ icon, children, ...rest }, ref) => {
  return (
    <Stack direction="row">
      <RoundContainer>
        <Icon path={icon} />
      </RoundContainer>
      <ColorTitle>{children}</ColorTitle>
    </Stack>
  );
});

TitleWithIcon.propTypes = {
  color: PropTypes.oneOf(['default']),
}

TitleWithIcon.defaultProps = {
  color: 'default',
};


export default TitleWithIcon;

const isAuthWithPIN = () => {
  if (getPINHash() !== "") {
    return true;
  }
  return false;
}

const isAuthWithSMS = () => {
  if (getToken() !== "") {
    return true;
  }
  return false;
}

const getToken = () => {
  return sessionStorage.getItem('token') || "";
};

const getPINHash = () => {
  return sessionStorage.getItem('PINHash') || "";
}

const clear = () => {
  sessionStorage.clear();
  localStorage.clear();
}

const setPINHash = hash => {
  if (hash) sessionStorage.setItem('PINHash', hash);
  return true;
}

const setToken = token => {
  if (token) sessionStorage.setItem('token', token);
  return true;
};


const Session = {
  isAuthWithPIN,
  isAuthWithSMS,
  clear,
  getPINHash,
  getToken,
  setPINHash,
  setToken
}

export default Session;

import React, { useEffect, useState } from 'react';
import { SwipeableList, SwipeableListItem, Type as ListType } from 'react-swipeable-list';

import VKUserPanel from './components/VKUserPanel';
import Api, { HttpMethods } from '@/services/api/api';
import { UIAlign, UIDirection } from '@/uikit/constants/UIConstants';
import Panel from '@/uikit/panel';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';
import 'react-swipeable-list/dist/styles.css';

const VKTeam = () => {

  const [myTeam, setData] = useState([])

  useEffect(() => {
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/team',
    }).then(response => {
      if (response.result) {
        setData(response.data);
      }
    })
  }, []);


  const renderTeam = () => {

    if (myTeam?.length === 0) {
      return <TextBox align={UIAlign.Center}>Список пуст.</TextBox>
    }

    return myTeam && myTeam.map((vkUser) => {
      const userVkId = vkUser.vk_id || vkUser.id;
      return (
        <SwipeableListItem>
          <VKUserPanel
            {...vkUser}
            info={
              <div>
                id:{userVkId}
              </div>
            }

          />
        </SwipeableListItem>
      );
    });
  }

  return (
    <Panel>
      <Stack>
        <Title>Моя команда</Title>
        <Stack gap="small">
          <TextBox>Участники команды никогда не смогут отправить запрос на добавление в друзья одним и тем же пользователям.</TextBox>
        </Stack>
        <SwipeableList type={ListType.ANDROID} style={{ display: "inline-table"}}>
          {renderTeam()}
        </SwipeableList>
      </Stack>
    </Panel>
  )
}
export default VKTeam;
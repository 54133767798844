import React from 'react';
import './style/style.less';

class CodeInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "    ",
    }
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.setState({
      value: event.target.value,
    });
    if (this.props.field && this.props.field.onChange) {
      this.props.field.onChange(event);
    }
  }

  render() {
    return <div className="kameta-code-input">

      <label htmlFor={"sms-code"} className="sms-code-input-label">
        <div className="sms-code">{this.state.value.charAt(0)}</div>
        <div className="sms-code">{this.state.value.charAt(1)}</div>
        <div className="sms-code">{this.state.value.charAt(2)}</div>
        <div className="sms-code">{this.state.value.charAt(3)}</div>
      </label>

      <input
        type="tel"
        id="sms-code"
        maxLength={4}
        {...this.props.field}
        onChange={this.changeValue}
      />

    </div>

  }

}

export default CodeInput;
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import UIConstants from '@/uikit/constants';

const StackRoot = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  gap: ${props => props.theme.components.stack.gap[props.gap]};
  justify-content: ${props => props.justifyContent};
  ${props => props.fullHeight && 
    css`
      height: 100%;
    `
  };
`;

const Stack = forwardRef(({ children, ...rest }, ref) => {
  return (
    <StackRoot ref={ref} {...rest}>
      {children}
    </StackRoot>
  );
});

Stack.propTypes = {
  gap: PropTypes.oneOf(['default', 'small', 'non', 'large']),
  direction: PropTypes.oneOf(UIConstants.UIDirection),
  justifyContent: PropTypes.oneOf(['center', 'none', 'space-between', 'space-around', 'flex-start']),
}

Stack.defaultProps = {
  gap: 'default',
  direction: UIConstants.UIDirection.Column,
};

export default Stack;

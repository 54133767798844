import React, { forwardRef } from 'react';
import styled from 'styled-components';

const SliderRoot = styled.div`
  background-color: #ffa500;
  background: ${props => props.background && 'url(' + props.background + ')'};
  background-size: cover;
  background-position: right;
  color: #FFFFFF;
  border-radius: 20px;
  height: 125px;
  padding: 10px;
  margin: 10px;
  width: 90%;
`;

const Slider = forwardRef(({ children, ...rest }, ref) => {
  return (
    <SliderRoot ref={ref} {...rest}>
      {children}
    </SliderRoot>
  );
});


export default Slider;

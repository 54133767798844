import LoadingScreenConstants from "../constants/LoadingScreenConstants";

const initialState = {
  action: LoadingScreenConstants.NONE,
}

export default function appReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case LoadingScreenConstants.SLOW_HIDE:
    case LoadingScreenConstants.SLOW_SHOW:
    case LoadingScreenConstants.NONE:
      return {
        action: action.type
      }
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
import SaverConstants from '@/constants/SaverConstants';
import Saver from '@/services/saver';

const initialState = {
  completedWord : [],
}

const tempData = initialState;

function saveReducer(state = initialState, action) {
  switch (action.type) {

    case SaverConstants.Actions.LOAD_LEVEL_DATA:
      const loadData = Saver.load('gameSave');
      return {
        ...state,
        ...loadData,
      }
    case SaverConstants.Actions.ADD_LEVEL_WORD:
      const { level, wordId } = action.value;
      if (!state.completedWord[level]) {
        state.completedWord[level] = [wordId];
      } else {
        state.completedWord[level].push(wordId);
      }
      Saver.save('gameSave', state);
      return {
        ...state,
      }
    default:
      return state;
  }
}

export default saveReducer;
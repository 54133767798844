import React from 'react';

import { connect } from 'react-redux';
import DataConstants from '@/constants/DataConstants';
import DataType from '@/constants/DataType';
import DataRender from '@/services/dataRender';
import Image from '@/uikit/image';
import Page from '@/uikit/page';
import Stack from '@/uikit/stack';
import Title from '@/uikit/title';

import './style/style.less';

class About extends React.Component {

  render() {
    const { page } = this.props;

    return (
      <Page withoutContent dataSource={DataConstants.ABOUT} dataType={DataType.PAGE}>
        <Stack>
          <Image src={page.logo} />
          <Title>{page.title}</Title>
          {DataRender.dataRender(page.data)}
        </Stack>
      </Page>
    )
  }

}

function mapStateToProps(state) {
  return {
    page: state.data.about.data || []
  };
}

export default connect(mapStateToProps)(About);
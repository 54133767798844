import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { UIColor } from '@/uikit/constants/UIConstants';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

const BlockRoot = styled.div`
`;

const Block = forwardRef(({ title, value, children, ...rest }, ref) => {
  return (
    <BlockRoot ref={ref}>
      <Title color={UIColor.Main}>{title}</Title>
      <TextBox>{value || children}</TextBox>
    </BlockRoot>
  );
});

export default Block;

import React from 'react';
import { connect } from 'react-redux';

import DataConstants from '@/constants/DataConstants';
import DataType from '@/constants/DataType';
import DataRender from "@/services/dataRender";
import Image from '@/uikit/image';
import Page from '@/uikit/page';
import Stack from '@/uikit/stack';

class Program extends React.Component {

  render() {
    const { page = {} } = this.props;
    return (
      <Page dataSource={DataConstants.PROGRAM} dataType={DataType.PAGE}>
        <Stack>
          <Image src={page.logo} />
          {DataRender.titleRender(page)}
          {DataRender.dataRender(page.data)}
        </Stack>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const page = state.data.program;
  return {
    ...state,
    page: page && page.data,
  };
}

export default connect(mapStateToProps)(Program);

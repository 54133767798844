import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import About from '@/pages/about';
import AuthPage from '@/pages/auth';
import Contacts from '@/pages/contacts';
import Main from '@/pages/main';
import NewsDetails from '@/pages/news/newsDetails';
import Office from '@/pages/office';
import Profile from '@/pages/profile';
import Program from '@/pages/program';
import RouterUtils from '@/services/routerUtils';
import UIConstants from '@/uikit/constants';
import { UIPadding } from '@/uikit/constants/UIConstants';

class App extends React.Component {

  theme = {
    colorBook: {
      default: '#212529',
      colorMain: '#0AD1C9',
      lightText: '#FFFFFF',
      darkText: '#808080',
      errorText: '#ff6a7e',
      background: '#FFFFFF',
      colorSecondary: '#CCC',
      colorBlackLight: '#404040',
    },
    colorMap: {
      [UIConstants.UIColor.Dark]: '#404040',
      [UIConstants.UIColor.Grey]: '#808080',
      [UIConstants.UIColor.Light]: '#efefef',
      [UIConstants.UIColor.Main]: '#0AD1C9',
      [UIConstants.UIColor.White]: '#FFFFFF',
    },
    components: {
      icon: {
        size: {
          [UIConstants.UISize.Default]: '30px',
          [UIConstants.UISize.Big]: '40px',
          [UIConstants.UISize.Large]: '100px',
        }
      },
      stack: {
        gap: {
          'large': '25px',
          'default': '20px',
          'small': '10px',
          'non': '0px',
        }
      },
      page: {
        padding: {
          [UIPadding.None]: '0px',
          [UIPadding.Default]: '10px',
        }
      },
      textbox: {
        indent: {
          [UIConstants.UIIndent.Large]: '40px',
          [UIConstants.UIIndent.Default]: '30px',
          [UIConstants.UIIndent.Small]: '10px',
          [UIConstants.UIIndent.None]: '0px',
        },
        size: {
          [UIConstants.UISize.Large]: '30px',
          [UIConstants.UISize.Big]: '25px',
          [UIConstants.UISize.Normal]: '20px',
          [UIConstants.UISize.Default]: '16px',
          [UIConstants.UISize.Small]: '14px',
        }
      }
    }
  };

  // TODO: Переделать навигацию https://codyhouse.co/ds/components/app/floating-navigation
  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <Routes>
          <Route path="/*" exact={true} element={<Main />} />
          <Route path="news/:id" element={<NewsDetails />} />
          <Route path="about" element={<About />} />
          <Route path="program" element={<Program />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="auth" element={<AuthPage />} />
          <Route path="profile" element={<Profile />} />
          <Route path="office" element={<Office />} />
        </Routes>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  const router = RouterUtils.getRouterInfo();
  return {
    ...state,
    router
  };
}

export default connect(mapStateToProps)(App);
import { AutoPlay } from '@egjs/flicking-plugins';
import Flicking from '@egjs/react-flicking';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DataConstants from '@/constants/DataConstants';
import DataType from '@/constants/DataType';
import Health from "@/content/image/slider/health.png?url";
import Security from "@/content/image/slider/security.png?url";
import Social from "@/content/image/slider/social.png?url";
import CalendarItem from '@/pages/office/CalendarItem';
import OfficeMenu from '@/pages/office/OfficeMenu';
import PollItem from '@/pages/office/PollItem';
import RouterUtils from '@/services/routerUtils';
import Box from '@/uikit/box';
import UIConstants from '@/uikit/constants';
import { UIColor, UISize } from '@/uikit/constants/UIConstants';
import Icon from '@/uikit/icon';
import RoundContainer from '@/uikit/icon/RoundContainer';
import Page from '@/uikit/page';
import Slider from '@/uikit/slider';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

import "@egjs/react-flicking/dist/flicking.css";
import 'react-spring-bottom-sheet/dist/style.css'

const MainBlock = styled.div`
  height: 100px;
  border-top: 1px solid #0ad1c9;
  padding: 25px 10px;
`;

const CustomIconContainer = styled(RoundContainer)`
  position: relative;
  width: 100%;
  right: 10px;
  top: -16px;
`;

const BlockRender = props => {
  const { title, headIcon, onClick, children } = props;

  return (
    <Stack gap="small" onClick={onClick}>
      <Title>{title}</Title>
      <CustomIconContainer
        background={UIConstants.UIColor.Main}
        border={UIConstants.UIColor.White}
        right>
        <Icon path={headIcon} />
      </CustomIconContainer>
      <MainBlock>
        <Stack gap="non">
          {children}
        </Stack>
      </MainBlock>
    </Stack>
  )
}

const Office = () => {

  const _plugins = [new AutoPlay()];
  const [ menuVisible, setMenuVisible ] = useState(true);

  const slideRender = (background, text, date) => {
    return (
      <Slider background={background}>
        <Stack gap="non">
          <TextBox
            align={UIConstants.UIAlign.Left}
            color={UIColor.White}
            indent={UIConstants.UIIndent.None}
            size={UISize.Normal}
            width={UIConstants.UIWidth.TwoThirds}>
            {text}
          </TextBox>
          <Box grow />
          <TextBox
            align={UIConstants.UIAlign.Right}
            color={UIColor.White}
            size={UISize.Normal}>
            {date}
          </TextBox>
        </Stack>
      </Slider>
    )
  }

  var startX, startY, endX, endY;
  var minDistance = 50;

  const touchStart = (event) => {
    startX = event.touches[0].clientX;
    startY = event.touches[0].clientY;
  }

  const touchEnd = (event) => {
    endX = event.changedTouches[0].clientX;
    endY = event.changedTouches[0].clientY;

    var deltaX = Math.abs(endX - startX);
    var deltaY = Math.abs(endY - startY);

    if (deltaX > minDistance || deltaY > minDistance) {
      if (deltaX > deltaY) {
        // Horizontal swipe
        if (endX > startX) {
          // Right swipe
          console.log('right');
        } else {
          // Left swipe
          console.log('left');
        }
      } else {
        // Vertical swipe
        if (endY > startY) {
          // Down swipe
          console.log('down');
          setMenuVisible(true);
        } else {
          // Up swipe
          console.log('up');
          setMenuVisible(false);
        }
      }
    }
  }

  return (
    <>
      <Page dataSource={DataConstants.USER_PROFILE} dataType={DataType.PAGE}>
        <Stack gap="large" onTouchStart={touchStart} onTouchEnd={touchEnd}>
          <Flicking renderOnlyVisible={true} circular={true} plugins={_plugins}>
            {slideRender(Health, "Донорская акция по сдаче крови", "18.04")}
            {slideRender(Social, "Круглый стол «Многодетные семьи»", "27.04")}
            {slideRender(Security, "Заседание комитета «Безопасное внешкольное время»", "03.04")}
          </Flicking>

          <BlockRender title="Календарь" headIcon="calendar2color">
            <CalendarItem />
          </BlockRender>

          <BlockRender title="Голосования" headIcon="vote">
            <PollItem
              info={"«Новые люди» предложили продлить выходные на майские праздники с 1 по 9 мая. Депутаты фракции Алексей Нечаев, Владислав Даванков, Сангаджи Тарбаев, Анна Скрозникова, Антон Ткачев и Георгий Арапов внесли соответствующий законопроект в Госдуму."}
              title={"Законопроект по продлению майских праздников"}
              answer={['За', 'Против']}
            />
          </BlockRender>
        </Stack>
      </Page>
      <OfficeMenu show={menuVisible}/>
    </>
  );

}
function mapStateToProps(state) {

  const router = RouterUtils.getRouterInfo(['news', 'id']);
  const newsId = router.params.id;

  const newsItems = state.data?.newsItems;

  return {
    ...state,
    item: newsItems && newsItems[newsId]?.data,
  };
}

export default connect(mapStateToProps)(Office);

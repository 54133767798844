export const UIAlign = {
  'Center': 'center',
  'Justify': 'justify',
  'Left': 'left',
  'Right': 'right',
  'Start': 'start',
  'End': 'end',
}

export const UIColor = {
  'Dark': 'dark',
  'Grey': 'grey',
  'Light': 'light',
  'Main': 'main',
  'White': 'white'
}

export const UIDirection = {
  'Column': 'column',
  'Row': 'row',
}

export const UIIndent = {
  'Large': 'large',
  'Default': 'default',
  'Small': 'small',
  'None': 'none'
}

export const UIPadding = {
  'Default': 'default',
  'None': 'none'
}

export const UISize = {
  'Large': 'large',
  'Big': 'big',
  'Normal': 'normal',
  'Default': 'default',
  'Small': 'small'
}

export const UIWeight = {
  'Normal': 300,
  'Encircle': 400,
}

export const UIWidth = {
  'Full': '100%',
  'Half': '50%',
  'TwoThirds': '66%',
}

export default {
  UIAlign,
  UIColor,
  UIDirection,
  UIIndent,
  UISize,
  UIWeight,
  UIWidth,
}
import * as VKID from '@vkid/sdk';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import VKUserPanel from './components/VKUserPanel';
import VKFindFriends from './vk_add_friends';
import VKBlackList from '@/components/vk/vk_black_list';
import VKStatistics from '@/components/vk/vk_statistics';
import VKTeam from '@/components/vk/vk_team';
import AttentionIcon from '@/content/icons/attention.svg';
import Api, { HttpMethods } from '@/services/api/api';
import Session from '@/services/Session';
import Box from '@/uikit/box';
import Button from '@/uikit/button';
import { UIColor, UIDirection, UISize } from '@/uikit/constants/UIConstants';
import Panel from '@/uikit/panel';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

const VkManagerRoot = styled.div`
  height: 100%;
`

const AttentionStack = styled(Stack)`
  border: 3px dotted #0AD1C9;
  border-radius: 5px;
  padding: 10px;
  
  svg {
    margin: auto;
  }
`;

const VKMainPanel = styled.div`
  background: #FFFFFF;
  border-radius: 25px 25px 0px 0px;
  padding: 25px;
  height: 100%;

  -webkit-box-shadow: 0px -12px 10px 0px rgba(34, 60, 80, 0.15);
  -moz-box-shadow: 0px -12px 10px 0px rgba(34, 60, 80, 0.15);
  box-shadow: 0px -12px 10px 0px rgba(34, 60, 80, 0.15);
`

const VkManager = props => {

  const navigate = useNavigate();
  const [user, setData] = useState([]);
  const [tasks, setTasks] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      Api.makeRequest({
        method: HttpMethods.GET,
        url: 'vk/user/tasks',
      }).then(response => {
        if (response.result) {
          setTasks(response.count);
        }
      })
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/user',
    }).then(response => {
      if (response.result) {
        setData(response.data);
      }
    })
  }, []);

  const logout = () => {
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/user/logout',
    }).then(response => {
      if (response.result) {
        VKID.Auth.logout(response.token);
        Session.clear();
        window.location.href = '/';
      }
    })
  }

  const renderJobsInformation = () => {
    if (!tasks || tasks === 0) return;
    return (
      <AttentionStack direction={UIDirection.Row} gap="small">
        <AttentionIcon style={{ fill: '#e84454', height: '36px'}}/>
        <Stack gap="non">
          <TextBox>В данный момент в фоновом режиме происходит добавление друзей. Если вы выйдите, то процесс прервется. Осталось: {tasks}</TextBox>
        </Stack>
      </AttentionStack>
    )
  }

  const StartPage = () => {


    return (
      <VkManagerRoot>
        <Stack fullHeight gap="non">
          <Panel>
            <VKUserPanel
              label={
                <TextBox size={UISize.Normal}>{user.first_name} {user.last_name}</TextBox>
              }
              noBorder
              {...user}/>
          </Panel>
          <VKMainPanel>
            <Stack fullHeight>
              {renderJobsInformation()}
              <VKStatistics />
              <Title>Управление</Title>
                <Stack gap="small">
                  <Button onClick={() => {navigate('addFriends');}}>Добавить в друзья</Button>
                  <Button onClick={() => {navigate('team');}}>Моя команда</Button>
                  <Button onClick={() => {navigate('blackList');}}>Черный список</Button>
                  <Button onClick={() => {logout();}}>Выход</Button>
                </Stack>
              <Box grow/>
            </Stack>
          </VKMainPanel>
        </Stack>
      </VkManagerRoot>
    )
  }

  return (
    <>
      <Routes>
        <Route path="" element={<StartPage />} />
        <Route path="addFriends" element={<VKFindFriends {...props}/>} />
        <Route path="team" element={<VKTeam {...props}/>} />
        <Route path="blackList" element={<VKBlackList {...props}/>} />
      </Routes>
    </>
  )

}

export default VkManager;

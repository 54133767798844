import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Box from '@/uikit/box';
import Button from '@/uikit/button';
import { UIColor, UIDirection, UISize } from '@/uikit/constants/UIConstants';
import { BottomDialog } from '@/uikit/dialog';
import Icon from '@/uikit/icon';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

const RootMenu = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
`;

const MenuContainer = styled.div`
  width: 300px;
  margin: 10px auto;
  height: 50px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
  border: 1px solid #f0f0f0;
  
  ${props => !props.show &&
  css`
    transform: translate(0, 85px);
  `}
  
  svg {
    transition: all 0.5s linear;
  }
`;

const MenuBox = styled.div`

  position: relative;
  display: block;
  width: 50px;
  height: 50px;

  label {
    display: block;
    padding: 10px;
  }
  input {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;

    & + label {
      svg {
        fill: ${props => props.theme.colorBook.darkText};
        transition: all 0.5s linear;
      }
    }
    
    &:checked + label {
      svg {
        fill: ${props => props.theme.colorMap[UIColor.Main]};
      }
    }
  }
`

class OfficeMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showClosingDialog: false,
    }

    this.clickAction = this.clickAction.bind(this);
  }

  clickAction(event) {
    if (event?.target?.id === "officeMenuItem_4") {
      event.preventDefault();
      this.showDialog(true);
    }
  }

  renderMenuItem(id, icon, active) {
    return (
      <MenuBox>
        <input id={"officeMenuItem_"+id} type="radio" name="answer" defaultChecked={active} onClick={this.clickAction}/>
        <label htmlFor={"officeMenuItem_"+id}>
          <Icon path={icon} />
        </label>
      </MenuBox>
    )
  }

  showDialog(value) {
    this.setState({
      showClosingDialog: value,
    })
  }

  render() {
    return (
      <RootMenu>
        <MenuContainer show={this.props.show}>
          <Stack gap="non" direction={UIDirection.Row} justifyContent="space-around">
            {this.renderMenuItem(1, 'home', true)}
            {this.renderMenuItem(2, 'calendar', false)}
            {this.renderMenuItem(3, 'person', false)}
            {this.renderMenuItem(4, 'exit', false)}
          </Stack>
        </MenuContainer>
        <BottomDialog
          close={() => this.showDialog(false)}
          icon="exit"
          open={this.state.showClosingDialog}
          minsize>
            <Stack gap="small">
              <Title>Подтверждение</Title>
              <TextBox size={UISize.Small}>
                Вы действительно хотите выйти из системы? После выхода из системы будет сброшен PIN-код.
              </TextBox>
              <Box />
              <Button onClick={() => this.showDialog(false)}>Выйти</Button>
              <Button color={UIColor.Grey} onClick={() => this.showDialog(false)}>Отмена</Button>
            </Stack>
        </BottomDialog>
      </RootMenu>
    )
  }
}

export default OfficeMenu;
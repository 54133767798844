import React from 'react';
import Block from '@/uikit/block';
import { UISize } from '@/uikit/constants/UIConstants';
import Link from '@/uikit/link';
import List from '@/uikit/list';
import Stack from '@/uikit/stack';
import Text from '@/uikit/text/Text';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

const renderLines = data => {
  try {
    const jsonData = JSON.parse(data);
    return jsonData.map(text => <Title>{text}</Title>);
  } catch (e) {
    return <Title>{data}</Title>;
  }
}

const titleRender = data => {
  if (!data.subtitle) {
    return (
      <Stack>
        {renderLines(data.title)}
      </Stack>
    );
  }
  return (
    <Stack gap="non">
      <Stack gap="non">
        {renderLines(data.title)}
      </Stack>
      <Stack gap="non">
        {renderLines(data.subtitle)}
      </Stack>
    </Stack>
  );
}

const dataRender = data => {
  if (!data) return null;
  const jsonData = JSON.parse(data);
  if (typeof(jsonData[0]) === 'string') {
    return jsonData.map(text => <TextBox>{text}</TextBox>);
  }

  return jsonData.map(item => {
    if (item.block) return <Block {...item.block} />;
    if (item.lines) return <Stack gap="non">{item.lines.map(text => <Text>{text}</Text>)}</Stack>
    if (item.text) return <TextBox>{item.text}</TextBox>;
    if (item.title) return <Title>{item.title}</Title>;
    if (item.link) return <Link {...item.link} />;
    if (item.list) return <List {...item.list} />;
  });
}

export default {
  titleRender,
  dataRender,
}
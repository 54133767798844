// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kameta-page-about--context {
  color: #212529;
  font-family: 'Avenir Next Cyr', sans-serif;
}
.kameta-page-about--context div {
  padding-bottom: 15px;
  font-size: 16px;
  text-indent: 20px;
  line-height: 26px;
  text-align: justify;
}
.kameta-page-about img {
  height: auto;
  width: 100%;
  border-radius: 5px;
  padding-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./pages/about/style/style.less"],"names":[],"mappings":"AAEE;EACE,cAAA;EACA,0CAAA;AADJ;AADE;EAKI,oBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AADN;AAVA;EAgBI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;AAHJ","sourcesContent":[".kameta-page-about {\n\n  &--context {\n    color: #212529;\n    font-family: 'Avenir Next Cyr', sans-serif;\n\n    div {\n      padding-bottom: 15px;\n      font-size: 16px;\n      text-indent: 20px;\n      line-height: 26px;\n      text-align: justify;\n    }\n  }\n\n  img {\n    height: auto;\n    width: 100%;\n    border-radius: 5px;\n    padding-bottom: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Block from '@/uikit/block';
import { UISize } from '@/uikit/constants/UIConstants';

const LinkRoot = styled.a`
  color: gray;
  text-decoration: none;
  font-size: ${props => props.theme.components.textbox.size[props.size]};
`;

const ClickLinkRoot = styled.span`
  color: gray;
  text-decoration: none;
  text-indent: 0px;
`;

const Link = forwardRef(({ title, type, value, ...rest }, ref) => {

  if (type === 'phone') {
    let fullPhone = value;

    if (value.length === 11) {
      if (value[0] === "7") fullPhone="+"+value;
    }

    if (value.length === 10) {
      fullPhone="+7"+value;
    }

    const phoneFormat = fullPhone.substring(0,2) +
      " (" + fullPhone.substring(2,5) +
      ") " + fullPhone.substring(5,8) +
      "-" + fullPhone.substring(8,10) +
      "-" + fullPhone.substring(10,12);

    return (
      <Block title={title}>
        <LinkRoot
          href={"tel:"+fullPhone}
          target="_blank">{phoneFormat}</LinkRoot>
      </Block>
    );
  }

  if (type === 'email') {
    return (
      <Block title={title}>
        <LinkRoot
          href={"mailto:"+value}
          target="_blank">{value}</LinkRoot>
      </Block>
    );
  }

  if (type === 'https' || type === 'http') {
    const openLink = () => {
      cordova.InAppBrowser.open(type+"://"+value, "_system", "location=yes");
    }
    return (
      <Block title={title}>
        <ClickLinkRoot onClick={openLink}>{value}</ClickLinkRoot>
      </Block>
    );
  }

  if (type === 'telegram') {
    const openTelegram = () => {
      cordova.InAppBrowser.open("tg://resolve?domain="+value, "_system", "location=yes");
    }
    return (
      <Block title={title}>
        <ClickLinkRoot onClick={openTelegram}>@{value}</ClickLinkRoot>
      </Block>
    );
  }

  return null;
});

export default Link;

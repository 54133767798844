
const getRouterInfo = (map = []) => {
  const hash = window.location.hash;
  const pathname = hash.indexOf('#/') === 0 ? hash.split("#/")[1] : hash;

  const allParams = pathname.split('/').filter((val) => val != "");
  let params = [];
  if (map) {
    let i = 0;
    map.forEach((index)=>{
      params[index] = allParams[i];
      i++;
    })
  }

  return {
    allParams,
    params,
    path: pathname,
  }
}

const RouterUtils = {
  getRouterInfo,
}

export default RouterUtils;
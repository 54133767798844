import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from "@/content/icons/loading.svg";

const PreloaderRoot = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #0000009c;
  z-index: 1000;
  margin: 0 auto;
  
  svg {
    height: 50%;
    width: 50%;
    top: 30%;
    position: relative;
    max-width: 150px;
  }
`;

let preloaderTimer = null;

const Preloader = props => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.isVisible) {
      clearInterval(preloaderTimer);
      setVisible(props.isVisible);
    } else {
      preloaderTimer = setTimeout(() => {
        setVisible(props.isVisible);
      }, 750);
    }
  }, [props.isVisible]);

  if (!visible) return null;

  return (
    <PreloaderRoot>
      <Loading />
    </PreloaderRoot>
  );
}

export default Preloader;
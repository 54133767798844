import Chart from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RadioGroup from '@/components/Input/RadioGroup';
import ChartIcon from '@/content/icons/chart.svg';
import LinesIcon from '@/content/icons/lines.svg';
import RotatePhone from '@/content/image/rotatePhone.png';
import Api, { HttpMethods } from '@/services/api/api';
import { UIDirection } from '@/uikit/constants/UIConstants';
import SvgIcon from '@/uikit/icon/SvgIcon';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/text/Text';
import Title from '@/uikit/title';


const StaticRoot = styled(Stack)`
  border-left: 3px solid #0AD1C9;
  padding: 10px;
`;

const ChartsCanvas = styled.div`
  
  #rotateIt {
    width: 250px;
    display: block;
    margin: auto;
    padding: 15px;
  }
`

const VKStatistics = props => {

  const [stats, setData] = useState([]);
  const [view, setView] = useState(0);
  const [deviceOrientation, setDeviceOrientation] = useState();

  const updateWindowOrientation = () => {
    if (window.innerWidth < window.innerHeight) {
      console.log('handleOrientation > set > W', window.innerWidth, window.innerHeight);
      setDeviceOrientation('W');
    } else {
      console.log('handleOrientation > set > H', window.innerWidth, window.innerHeight);
      setDeviceOrientation('H');
    }
  }

  const handleOrientation = () => {
    setTimeout(updateWindowOrientation, 500);
  }

  const supportsOrientationChange = "onorientationchange" in window;

  if (supportsOrientationChange) {
    window.addEventListener("orientationchange", handleOrientation, false);
  }


  useEffect(() => {
    handleOrientation();
  }, []);


  useEffect(() => {
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/friends/statistics',
    }).then(response => {
      if (response.result) {
        setData(response.data);
      }
    })
  }, []);

  useEffect(() => {
    if (view == 0) return;
    if (document.getElementById('acquisitions') === null) return;
    const data = stats.statistics;
    if (!data) return;


    const totalDuration = 1500;
    const delayBetweenPoints = totalDuration / data.length;
    const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
    const animation = {
      x: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: NaN, // the point is initially skipped
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0;
          }
          ctx.xStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      },
      y: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: previousY,
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.yStarted) {
            return 0;
          }
          ctx.yStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      }
    };

    new Chart(
      document.getElementById('acquisitions'),
      {
        type: 'line',
        data: {
          labels: data.map(row => row.date),
          datasets: [
            {
              borderColor: "#0AD1C9",
              label: 'Кол-во запросов',
              data: data.map(row => row.requests)
            },
            {
              borderColor: "#e84454",
              label: 'Количество новых друзей',
              data: data.map(row => row.friends)
            }
          ]
        },
        options: {
          animation,
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
              },
              position: 'bottom',
            }
          }
        }
      }
    );

  }, [view, deviceOrientation])

  const Block = ({title, value}) => {
    return (
      <Stack gap="small" direction={UIDirection.Row} justifyContent="space-between">
        <TextBox>
          {title}
        </TextBox>
        <TextBox>
          {value}
        </TextBox>
      </Stack>
    )
  }

  const getEffectiveness = () => {
    const { friends = 0, requests = 0 } = stats;
    if (requests > 0) {
      return Math.round(friends / requests * 100);
    } else {
      return 0;
    }

  }

  const renderStatistics = () => {
    if (view == 0) {
      return (
        <StaticRoot gap="small">
          <Block title="Кол-во запросов" value={stats.requests || 0} />
          <Block title="Кол-во новых друзей" value={stats.friends || 0} />
          <Block title="Эффективность" value={getEffectiveness() + "%"} />
        </StaticRoot>
      )

    }

    console.log('render > deviceOrientation', deviceOrientation);
    if (supportsOrientationChange && (deviceOrientation !== 'H')) {
      return (
        <img id="rotateIt" src={RotatePhone} />
      );
    }

    return (
      <canvas id="acquisitions" width="400" height="100" aria-label="Hello ARIA World" role="img"></canvas>
    )


  }

  return (
    <Stack>
      <Stack direction={UIDirection.Row}>
        <Title>Статистика</Title>

        <RadioGroup
          onChange={(_, value) => setView(value)}
          name="statisticsView"
          value={view}
          items={[
            {
              elem: <SvgIcon><LinesIcon/></SvgIcon>,
              value: 0,
            },
            {
              elem:  <SvgIcon><ChartIcon/></SvgIcon>,
              value: 1,
            }
          ]}
        />
      </Stack>
      <ChartsCanvas>
        {renderStatistics()}
      </ChartsCanvas>
    </Stack>
  )
}

export default VKStatistics;

const Actions = {
  ADD_LEVEL_WORD: 'ADD_LEVEL_WORD',
  LOAD_LEVEL_DATA: 'LOAD_LEVEL_DATA',
}

const SaverConstants = {
  Actions,
}

export default SaverConstants;
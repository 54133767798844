import React from 'react';
import Request from 'superagent';
import Session from '@/services/Session';

let apiToken = "";

export const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
};

const Methods = {
    [HttpMethods.GET]:  'get',
    [HttpMethods.POST]: 'post'
}

const makeVkRequest = props => new Promise((resolve, reject) => {
    const { method = Methods.GET, params = {} } = props;
    const requestUrl = 'https://id.vk.com/oauth2/auth';

    const request = Request.post(requestUrl);
    request.set('Content-Type', 'application/x-www-form-urlencoded');
    if (params.data) request.send(params.data);

    request.end((error, response) => {
        console.log(response);
        resolve(response);
    });
});


const makeRequest = props => new Promise((resolve, reject) => {
    const { method = Methods.GET, url, params = {}, action } = props;
    const requestUrl = 'https://kameta.studio/newpeople/public/api/' + url;
    //const requestUrl = 'http://127.0.0.1:8000/api/' + url;

    const request = Request[Methods[method]](requestUrl);
    request.set('Accept', 'application/json');

    if (!apiToken) {
        apiToken = Session.getToken();
    }
    if (apiToken !== "") request.set('Authorization', "Bearer "+apiToken);

    if (params.data) {
        if (method === Methods.GET) {
            request.query(params.data);
        } else {
            request.send(params.data);
        }
    }

    request.end((error, response) => {
        if (action) action();
        if (response.statusCode && response.statusCode === 200) {
            if (response.body.result) {
                resolve(response.body);
            } else {
                reject(response.body);
            }
        }
        if (response.statusCode && response.statusCode === 401) {
            //TODO: здесь должен быть редирект на страницу авторизации
            // Проблема с AsincLoader'ом:
            // в useEffect поместить useNavigate нельзя
            Session.clear();
            reject({ code: 401 });
        }
    });
});


const saveToken = token => {
  if (token) {
      apiToken = token;
      Session.setToken(token);
  }
};

const Api = {
    makeRequest,
    makeVkRequest,
    saveToken
}

export default Api;

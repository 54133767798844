import React from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import Stack from '@/uikit/stack';

const RadioGroupRoot = styled.div`
  
  input[type=radio] {
    display: none;
  }
  
  label {
    box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.1) inset;
    svg {
      fill: #b8b8b8;
    }
  }
  
  input[type=radio]:checked + label {
    background: #0AD1C9;
    box-shadow: none;
    svg {
      fill: #FFFFFF;
    }
  }
  
`

const RadioGroupLabel = styled.label`
  width: 34px;
  height: 34px;
  background: #ffffff;
  display: block;
  padding: 4px;
  border-radius: 10px;
  box-sizing: border-box;
`

const RadioGroup = props => {

  const onChangeValue = event => {
    const value = event.target.value;
    props.onChange && props.onChange(event, value);
  }

  let i = 0;
  const items = props.items.map(item =>{
    i++;
    const isChecked = item.value == props.value ? {checked: "checked"} : {};
    return (
      <RadioGroupRoot>
        <input id={props.name+'_'+i} type="radio" name={props.name} value={item.value} onChange={onChangeValue} {...isChecked}/>
        <RadioGroupLabel for={props.name+'_'+i}>
          {item.elem}
        </RadioGroupLabel>
      </RadioGroupRoot>
    );
  });

  return (
    <>
      {items}
    </>
  )

}

export default RadioGroup;
import React from 'react';
import styled, { css } from 'styled-components';
import { UIDirection } from '@/uikit/constants/UIConstants';
import Stack from '@/uikit/stack';

const VKAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid rgb(184, 184, 184);
  padding: 3px;
`;

const UserPanel = styled(Stack)`
  width: 100%;
  line-height: 60px;
  color: rgb(128, 128, 128);
  box-sizing: border-box;
  margin: 2px 0px;

  ${props => !props.noBorder &&
    css`
      border: 1px solid rgb(184, 184, 184);
      padding: 5px 10px;
      border-radius: 10px;
    `
  };
  
  a {
    text-decoration: none;
    color: #808080;
  }
`;

const UserInfo = styled(Stack)`
  line-height: 30px;
`

const VKUserPanel = props => {
  const id = props.vk_id || props.id;
  const vkLink = 'https://vk.com/id'+id;

  const renderLabel = () => {
    if (props.label) return props.label;
    return props.first_name + ' ' + props.last_name;
  }

  const renderUserInfo = () => {
    if (props.info) {
      return (
        <UserInfo gap="none">
          <a href={vkLink} target="_blank">
            <span>{renderLabel()}</span>
          </a>
          {props.info}
        </UserInfo>
      )
    }
    return (
      <a href={vkLink} target="_blank">
        <span>{renderLabel()}</span>
      </a>
    )
  }

  return (
    <UserPanel direction={UIDirection.Row} {...props}>
      <VKAvatar src={props.photo_200}/>
      {renderUserInfo()}
    </UserPanel>
  );
};

export default VKUserPanel;
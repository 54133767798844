import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import {store} from "./createStore";

import './default.less';

// https://youtu.be/eSaF8NXeNsA?t=2095



const application = (
  <Provider store={store} >
    <HashRouter>
      <Suspense fallback="">
        <App />
      </Suspense>
    </HashRouter>
  </Provider>
);


ReactDOM.render(
  application, document.getElementById('root')
);

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { UIColor } from '@/uikit/constants/UIConstants';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';
import TitleWithIcon from '@/uikit/title/TitleWithIcon';

const ListRoot = styled.ul`
  list-style-position: inside;
  padding-inline-start: 15px;
`;

const ListItemRoot = styled.li`
  color:  ${props => props.theme.colorMap[props.color]};
`;

const List = forwardRef(({ icon, items, title,  ...rest }, ref) => {
  return (
    <Stack gap="small" ref={ref}>
      { icon ?
        <TitleWithIcon icon={icon}>{title}</TitleWithIcon> :
        <Title>{title}</Title>
      }
      <ListRoot>
        {items.map(record =>
          <ListItemRoot color={rest.color}>
            <TextBox>{record}</TextBox>
          </ListItemRoot>
        )}
      </ListRoot>
    </Stack>
  );
});

List.propTypes = {
  color: PropTypes.oneOf(UIColor),
}

List.defaultProps = {
  color: UIColor.Grey,
};

export default List;

import React, { useEffect, useState } from 'react';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import styled from 'styled-components';
import VKUserPanel from './components/VKUserPanel';
import AttentionIcon from '@/content/icons/attention.svg';
import RecoveryIcon from '@/content/icons/recovery.svg';
import Api, { HttpMethods } from '@/services/api/api';
import { UIAlign, UIDirection } from '@/uikit/constants/UIConstants';
import Panel from '@/uikit/panel';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';
import 'react-swipeable-list/dist/styles.css';

const AttentionStack = styled(Stack)`
  border: 3px dotted #0AD1C9;
  border-radius: 5px;
  padding: 10px;
  
  svg {
    margin: auto;
  }
`;

const VKRecoveryPanel = styled.div`
  line-height: 45px;
  width: 50px;
  background: #0AD1C9;
  padding: 10px;
  border: 1px solid #005d59;
  margin: 2px 10px;
  text-align: center;
  border-radius: 5px;
  
  svg {
    height: 32px;
    padding: 6px;
    fill: white;
  }
`;

const VKBlackList = () => {

  const [blacklist, setData] = useState([])

  useEffect(() => {
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/blacklist',
    }).then(response => {
      if (response.result) {
        setData(response.data);
      }
    })
  }, []);

  const removeFromBlackListRequest = (id) => {
    Api.makeRequest({
      method: HttpMethods.POST,
      url: 'vk/blacklist/remove',
      params: {
        data: {
          vk_id: id,
        }
      },
    })
  }

  const trailingActions = (id) => (
    <TrailingActions>
      <SwipeAction destructive={true} onClick={() => removeFromBlackListRequest(id)}>
        <VKRecoveryPanel>
          <RecoveryIcon />
        </VKRecoveryPanel>
      </SwipeAction>
    </TrailingActions>
  );

  const renderBlacklist = () => {

    if (blacklist?.length === 0) {
      return <TextBox align={UIAlign.Center}>Список пуст.</TextBox>
    }

    return blacklist && blacklist.map((vkUser) => {

      return (
        <SwipeableListItem
          trailingActions={trailingActions(vkUser.vk_id)}
        >
          <VKUserPanel {...vkUser} />
        </SwipeableListItem>
      );
    });
  }

  return (
    <Panel>
      <Stack>
        <Title>Черный список</Title>
        <Stack gap="small">
          <TextBox>Пользователям, которые были добавлены в черный список, не будут приходить от вас запросы на добавление в друзья, а так же сообщения при массовой рассылке.</TextBox>
        </Stack>
        <AttentionStack direction={UIDirection.Row} gap="small">
          <AttentionIcon style={{ fill: '#e84454', height: '36px'}}/>
          <TextBox>Чтобы удалить пользователя из черного листа, смахните его вправо в списке ниже</TextBox>
        </AttentionStack>
        <SwipeableList type={ListType.ANDROID} style={{ display: "inline-table"}}>
          {renderBlacklist()}
        </SwipeableList>
      </Stack>
    </Panel>
  )
}
export default VKBlackList;
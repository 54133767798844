import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Title from '@/uikit/title';

const DialogRoot = styled.div`
  background-color: white;
  min-height: 150px;
  border-radius: 25px;
  text-align: center;
  padding: 25px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e7e7e7;
  ${props => props.hide && 
    css`
      animation:hideDialog 1000ms ease-in-out;
      animation-iteration-count: 1;
    `
  };
  ${props => !props.hide &&
    css`
      animation-delay: 3s;
      animation:showDialog 1000ms ease-in-out;
      animation-iteration-count: 1;
    `
  };
`;
// https://freefrontend.com/css-modal-windows/page/2/
// https://codepen.io/D3V1L/pen/mEKjYm/
const Dialog = forwardRef(({ children, ...rest }, ref) => {
  return (
    <DialogRoot ref={ref} {...rest}>
      {children}
    </DialogRoot>
  );
});

Dialog.propTypes = {
  hide: PropTypes.bool,
}

Dialog.default = {
  hide: false,
}

export default Dialog;
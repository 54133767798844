import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import DataActions from '@/actions/DataActions';
import DataConfigs from '@/configs/DataConfigs';
import DataType from '@/constants/DataType';
import FetchConstants from '@/constants/FetchConstants';

import Api, { HttpMethods } from '@/services/api/api';
import Icon from '@/uikit/icon';
import BoxContainer from '@/uikit/icon/BoxContainer';

const AsyncLoader = props => {

  const [status, setStatus] = useState(FetchConstants.NONE);
  const [path] = useState(props.dataSource ? DataConfigs[props.dataSource].path : "");
  const [id] = useState(window.location.hash.split("/")[2] || "");
  const [page, setPage] = useState(1);
  const [autoLoading, setAutoLoading] = useState(true);
  const navigate = useNavigate();
  const { dataSource, dataType } = props;

  const getURL = path => {
    let url = path;
    if (dataType == DataType.ITEM) {
      url = url + "/" + id;
    }
    return url;
  }

  const fetchData = () => {
    setStatus(FetchConstants.START_LOADING);
    Api.makeRequest({
      method: HttpMethods.GET,
      url: getURL(path),
    }).then(response => {
      if (response) {
        DataActions.setData({dataSource, dataType, data: response.data, itemId: id});
        setStatus(FetchConstants.READY);
      } else {
        setStatus(FetchConstants.ERROR_LOADING);
      }
    }).catch(error => {
      // TODO: Перенести navigate в Api
      console.log('Error > ', error);
      if (error.code === 401) {
        console.log('go auth')
        navigate('/auth');
      }
    });
  }

  const silentFetchData = (add) => {
    Api.makeRequest({
      method: HttpMethods.GET,
      url: getURL(path+add),
    }).then(response => {
      if (response) {
        DataActions.addData({dataSource, dataType, data: response.data, itemId: id});
        if (response.data.length === 0) setAutoLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, [path]);

  const isList = dataType === DataType.LIST;

  const StaticComponent = props.addComponent;

  return <InfiniteScroll
    dataLength={props.itemListCount*page}
    style={{
      height: window.innerHeight,
      flexDirection: 'column-reverse',
    }}
    next={() => {
      if (!isList) return;
      setPage(page+1);
      silentFetchData('?page='+page);
    }}
    hasMore={isList && autoLoading}
    height={'auto'}
    refreshFunction={() => {
      DataActions.setData({dataSource, dataType, data: []});
      setPage(1);
      setAutoLoading(true);
      fetchData();
    }}
    pullDownToRefresh={isList}
    pullDownToRefreshThreshold={50}
    scrollThreshold={0.8}
    pullDownToRefreshContent={
      <BoxContainer>
        <Icon path="loading" />
      </BoxContainer>
    }
    releaseToRefreshContent={
      <BoxContainer>
        <Icon path="loading" />
      </BoxContainer>
    }
  >
    {path && status === FetchConstants.ERROR_LOADING && <div>Не удалось загрузить страницу</div>}
    {status === FetchConstants.READY && props.children}
    {props.addComponent && <StaticComponent />}
  </InfiniteScroll>

}

AsyncLoader.defaultProps = {
  addComponent: null,
  itemListCount: 1,
}

export default AsyncLoader;
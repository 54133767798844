import { combineReducers } from "redux";
import appReducer from "./appReducer";
import dataReducer from "./dataReducer";
import loadingScreenReducer from "./loadingScreenReducer";
import mainPageReducer from "./mainPageReducer";
import saveReducer from "./saveReducer";

const rootReducer = combineReducers({
    app: appReducer,
    loadingScreen: loadingScreenReducer,
    mainPage: mainPageReducer,
    save: saveReducer,
    data: dataReducer,
})

export default rootReducer;
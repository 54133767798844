import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Session from '@/services/Session';
import Box from '@/uikit/box';
import { UIColor, UIDirection } from '@/uikit/constants/UIConstants';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/text/Text';

const PINKeyboardRoot = styled.div`
  margin: auto;
  width: 250px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const KeyRoot = styled.div`
  border: 1px solid ${props => props.theme.colorMap[UIColor.Grey]};
  border-radius: 250px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 70px;
  font-size: x-large;
  color: ${props => props.theme.colorMap[UIColor.Grey]};
  margin: 10px;
  display: inline-block;

  &.animate {
    animation:PINKeyboardClickAnimation 700ms ease-in-out;
    animation-iteration-count: 1;
  }
`;

const InsertPoint = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.theme.colorMap[UIColor.Grey]};
  border-radius: 10px;

  ${props => props.active && css`
    background-color: ${props => props.theme.colorMap[UIColor.Main]};
  `};
`;

const PINKeyboard = props => {

  const [ pinValue, setPINValue ] = useState("");

  const keys = [1,2,3,4,5,6,7,8,9,0];

  const playAnimation = event => {

    const pinCodeValue = pinValue+event.target.id;

    console.log("playAnimation > ", pinCodeValue );
    setPINValue(pinCodeValue);
    event.target.classList.remove('animate');

    if (pinCodeValue.length === 4) {
      console.log('Done');
      Session.setPINHash(pinCodeValue);
      props.onComplite && props.onComplite();
    }

    setTimeout(() => {
      event.target.classList.add('animate');
    }, 0);
  }

  const renderKeys = () => {
    return keys.map(key => <KeyRoot id={key} onClick={playAnimation}>{key}</KeyRoot>)
  }

  return (
    <PINKeyboardRoot gap="large">
      <Stack gap="large">
        <TextBox align="center">Ведите PIN-код</TextBox>
        <Stack direction={UIDirection.Row} justifyContent="center">
          <InsertPoint active={pinValue.length >= 1} />
          <InsertPoint active={pinValue.length >= 2} />
          <InsertPoint active={pinValue.length >= 3} />
          <InsertPoint active={pinValue.length >= 4} />
        </Stack>
        <Box />
        <Box />
      </Stack>
      {renderKeys()}
    </PINKeyboardRoot>
  );
}

export default PINKeyboard;
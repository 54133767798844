import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';
import PhoneInput from '@/components/Input/PhoneInput';
import Stack from '@/uikit/stack';

const InputFieldRoot = styled(Stack)`
  input {
    width: 100%;
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    outline: 0;
    padding: 0px 20px;
    box-sizing: border-box;

    &:not(&:placeholder-shown) {
      & + label {
        line-height: unset;
        margin: -8px 20px;
        background: white;
        border: 1px solid #e7e7e7;
        border-radius: 50px;
        color: ${props => props.theme.colorBook.darkText};
      }
    }

    &:focus + label
    {
      line-height: unset;
      margin: -8px 20px;
      background: white;
      border: 1px solid #e7e7e7;
      border-radius: 50px;
      color: ${props => props.theme.colorBook.darkText};
    }
  }
`;

const LabelRoot = styled.label`
  position: absolute;
  line-height: 45px;
  padding: 0px 20px;
`;

const InputField = forwardRef(({ children, placeholder, ...rest }) => {
  return(
    <InputFieldRoot {...rest.field} gap="non">
      <InputMask id={rest.field.name} placeholder={""} {...rest.field}>
        {children}
      </InputMask>
      <LabelRoot htmlFor={rest.field.name}>{placeholder}</LabelRoot>
    </InputFieldRoot>
  )
});

export default InputField;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import Logo from "../../content/image/logos/Classic.svg";
import CodeInput from '@/components/Input/CodeInput';
import PhoneInput from '@/components/Input/PhoneInput';
import PINKeyboard from '@/components/PIN';
import Api, { HttpMethods } from '@/services/api/api';
import RouterUtils from '@/services/routerUtils';
import Session from '@/services/Session';
import Button from '@/uikit/button';
import Dialog from '@/uikit/dialog';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/text/Text';
import Title from '@/uikit/title';

const StackWithPadding = styled(Stack)`
  padding-top: 50px;
  box-sizing: border-box;
`;

const AuthPage = () => {

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [hide, setHide] = useState(-1);

  const checkData = data => {
    const numberPattern = /\d+/g;
    const phoneValue = data.phone.match(numberPattern).join('');
    
    if (phoneValue.length === 11) {
      Api.makeRequest({
        method: HttpMethods.POST,
        url: 'prelogin',
        params: { data: { phone: phoneValue } }
      }).then(()=> {
        setTimeout(()=> {
          setHide(0);
          setTimeout(()=> {
            setStep(1);
          }, 500);
        }, 500);
      })
    } else {
      setError('phone');
    }
  }

  const sendSMSCode = data => {
    const numberPattern = /\d+/g;
    const smsValue = data.smscode && data.smscode.match(numberPattern).join('');
    const phoneValue = data.phone && data.phone.match(numberPattern).join('');

    if (smsValue.length === 4 && phoneValue.length === 11) {
      Api.makeRequest({
        method: HttpMethods.POST,
        url: 'login',
        params: { data: { otp: smsValue, phone: phoneValue } }
      }).then(response => {
        setTimeout(() => {
          setHide(1);
          setTimeout(() => {
            setStep(2);
            Api.saveToken(response.access_token);
            checkProfile();
          }, 500);
        }, 500);
      }).catch(() => {
        setError('smscode', { message: 'Указан неверный код из СМС' });
      })
    } else {
      setError('smscode', { message: 'Не указан код из СМС' });
    }
  }

  const checkProfile = () => {
    Api.makeRequest({
      method: HttpMethods.POST,
      url: 'userprofile',
    }).then(response => {
      if (response.profile.need_create) {
        navigate('/profile');
      } else {
        navigate('/office');
      }
    });
  }

  const renderPhoneStep = () => {
    return (
      <Stack>
        <Stack gap="non">
          <PhoneInput field={register('phone', { pattern: /\d+/ })} />
          <TextBox align="center">
            {errors.phone ? "Не заполнен номер телефона" : "Для регистрации или входа в личный кабинет введите ваш номер телефона."}
          </TextBox>
        </Stack>
        <Button onClick={handleSubmit(checkData)}>Продолжить</Button>
      </Stack>
    );
  }

  const renderSMSCodeStep = () => {
    return (
      <Stack gap="non">
        <CodeInput field={register('smscode', { pattern: /\d+/ })} />
        <TextBox align="center">
          {errors.smscode?.message || "Введите код из СМС."}
        </TextBox>
        <Button onClick={handleSubmit(sendSMSCode)}>Продолжить</Button>
      </Stack>
    );
  }

  const renderSteps = () => {
    switch (step) {
      case 0:
        return renderPhoneStep();
        break;
      case 1:
        return renderSMSCodeStep();
        break;
    }
  }

  const renderDialog = id => {
    if (step > 1) return;
    return (
      <Dialog hide={id === hide}>
        <Stack>
          <Title>Личный кабинет</Title>
          {renderSteps()}
        </Stack>
      </Dialog>
    );
  }

  if (!Session.isAuthWithSMS()) {
    return(
      <StackWithPadding gap="non">
        <form>
          <Logo />
          {renderDialog(step)}
        </form>
      </StackWithPadding>
    );
  }

  if (!Session.isAuthWithPIN()) {
    return <PINKeyboard onComplite={() => {
      navigate('/office');
    }}/>
  }
}
function mapStateToProps(state) {
  const router = RouterUtils.getRouterInfo();
  return {
    ...state,
    router
  };
}

export default connect(mapStateToProps)(AuthPage);

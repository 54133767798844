import DataConstants from '../constants/DataConstants';

const DataConfigs = {
    [DataConstants.NEWS]: {
        path: 'news',
        attr: 'id',
        storeKey: DataConstants.NEWS,
    },
    [DataConstants.NEWS_ITEM]: {
        path: 'news',
        attr: 'id',
        storeKey: DataConstants.NEWS_ITEM,
    },
    [DataConstants.ABOUT]: {
        path: 'static/about',
        storeKey: DataConstants.ABOUT,
    },
    [DataConstants.PROGRAM]: {
        path: 'static/program',
        storeKey: DataConstants.PROGRAM,
    },
    [DataConstants.CONTACTS]: {
        path: 'static/contacts',
        storeKey: DataConstants.CONTACTS,
    },
    [DataConstants.USER_PROFILE]: {
        path: 'userprofile',
        storeKey: DataConstants.USER_PROFILE,
    }

}

export default DataConfigs;
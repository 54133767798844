import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { UIAlign, UIColor, UIIndent, UISize, UIWeight, UIWidth } from '@/uikit/constants/UIConstants';

const TextBoxRoot = styled.span`
  font-size: ${props => props.theme.components.textbox.size[props.size]};
  font-weight: ${props => props.weight};
  text-indent: ${props => props.theme.components.textbox.indent[props.indent]};
  text-align: ${props => props.align};
  width: ${props => props.width};
  color: ${props => props.theme.colorMap[props.color]};
  display: block;
  margin: auto;
`;

const TextBox = forwardRef(({ children, ...rest }, ref) => {
  return (
    <TextBoxRoot ref={ref} {...rest}>
      {children}
    </TextBoxRoot>
  );
});

TextBox.propTypes = {
  align: PropTypes.oneOf(UIAlign),
  color: PropTypes.oneOf(UIColor),
  indent: PropTypes.oneOf(UIIndent),
  size: PropTypes.oneOf(UISize),
  weight: PropTypes.oneOf(UIWeight),
  width: PropTypes.oneOf(UIWidth),
}

TextBox.defaultProps = {
  align: UIAlign.Justify,
  color: UIColor.Grey,
  indent: UIIndent.Default,
  size: UISize.Default,
  weight: UIWeight.Normal,
  width: UIWidth.Full,
};

export default TextBox;

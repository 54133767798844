import AppConstants from 'src/constants/AppConstants';

const initialState = {
  scrollValue: 0,
  router: {},
}


export default function appReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case AppConstants.ACTIONS.UPDATE_ROUTER_DATA:
      return {
        ...state,
        router: action.value,
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
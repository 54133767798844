const ERROR_LOADING = 'ERROR_LOADING';
const START_LOADING = 'START_LOADING';
const NONE = 'NONE';
const READY = 'READY';

const FetchConstants = {
    NONE,
    START_LOADING,
    ERROR_LOADING,
    READY,
}

export default FetchConstants;
import React from 'react';
import { connect } from 'react-redux';

import DataConstants from '@/constants/DataConstants';
import DataType from '@/constants/DataType';
import DataRender from '@/services/dataRender';
import RouterUtils from '@/services/routerUtils';
import Image from '@/uikit/image';
import Mark from '@/uikit/mark';
import Page from '@/uikit/page';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

class NewsPage extends React.Component {

  renderTag(tagId) {

    switch (tagId) {
      case 1:
        return "ДРУГОЕ";
        break;
      case 2:
        return "ГОСДУМА"
        break;
      default:
        return "ДРУГОЕ";
    }
  }

  render() {
    const { item = {} } = this.props;
    return (
      <Page dataSource={DataConstants.NEWS_ITEM} dataType={DataType.ITEM}>
        <Stack>
          <Image src={item.logo} />
          <Mark>{this.renderTag(item.category)}</Mark>
          <Title>{item.title}</Title>
          {item.time && <TextBox>{item.time}</TextBox>}
          {DataRender.dataRender(item.data)}
        </Stack>
      </Page>
    );
  }
}
function mapStateToProps(state) {

  const router = RouterUtils.getRouterInfo(['news', 'id']);
  const newsId = router.params.id;

  const newsItems = state.data?.newsItems;

  return {
    ...state,
    item: newsItems && newsItems[newsId]?.data,
  };
}

export default connect(mapStateToProps)(NewsPage);

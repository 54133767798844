import React, { forwardRef } from 'react';
import styled from 'styled-components';

const PanelRoot = styled.div`
  padding: 25px;
`;

const Panel = forwardRef(({ children, ...rest }, ref) => {
  return (
    <PanelRoot ref={ref} {...rest}>
      {children}
    </PanelRoot>
  );
});


export default Panel;

import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import { UIColor, UISize } from '@/uikit/constants/UIConstants';

const TitleRoot = styled.h1`
  font-size: ${props => props.theme.components.textbox.size[props.size]};
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.colorMap[props.color]}; 
  margin: auto 0px;
`;

const Title = forwardRef(({ children, ...rest }, ref) =>
  <TitleRoot {...rest}>
    {children}
  </TitleRoot>
);

Title.propTypes = {
  color: PropTypes.oneOf(UIColor),
  size: PropTypes.oneOf(UISize),
}

Title.defaultProps = {
  color: UIColor.Dark,
  size: UISize.Default,
};


export default Title;

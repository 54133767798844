const Prefix = 'MAIN_PAGE';

const GetKey = (key) => {
    return Prefix+'_'+key;
}

const Actions = {
    FETCH_DATA: GetKey('FETCH_DATA'),
}

const MainPageConstants = {
    Actions,
}

export default MainPageConstants;
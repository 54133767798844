import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import UIConstants from '@/uikit/constants/UIConstants';

const MainContainerRoot = styled.div`
  width: 100%;
  top: -28px;
  position: absolute;
`;

const HeadContainerRoot = styled.div`
  height: ${props => props.theme.components.icon.size[props.size]};
  width: ${props => props.theme.components.icon.size[props.size]};
  border: 3px solid ${props => props.theme.colorMap[props.border]};
  background-color: ${props => props.theme.colorMap[props.background]};
  border-radius: 250px;
  padding: 10px;
  margin: 0 auto;
`;

const HeadContainer = forwardRef(({ background, border, size, children, ...rest }, ref) => {
  return (
    <MainContainerRoot ref={ref}>
      <HeadContainerRoot background={background} border={border} size={size}>
        {children}
      </HeadContainerRoot>
    </MainContainerRoot>
  );
});

HeadContainer.propTypes = {
  border: PropTypes.oneOf(UIConstants.UIColor),
  background: PropTypes.oneOf(UIConstants.UIColor),
  size: PropTypes.oneOf(UIConstants.UISize),
}

HeadContainer.defaultProps = {
  border: UIConstants.UIColor.Main,
  background: UIConstants.UIColor.White,
  size: UIConstants.UISize.Default,
};

export default HeadContainer;

import FetchConstants from '@/constants/FetchConstants';
import { dispatch } from '@/createStore';

const addData = ({ dataSource, dataType, data, status = FetchConstants.READY, itemId }) => dispatch({
  type: 'DATA_ACTION_ADD',
  dataStatus: status,
  dataType: dataType,
  value: {
    dataSource,
    data,
    itemId
  }
});

const setData = ({ dataSource, dataType, data, status = FetchConstants.READY, itemId }) => dispatch({
  type: 'DATA_ACTION_SET',
  dataStatus: status,
  dataType: dataType,
  value: {
    dataSource,
    data,
    itemId
  }
});

const setError = (dataSource, dataType, itemId = null) => dispatch({
  type: 'DATA_ACTION_SET',
  dataStatus: FetchConstants.ERROR_LOADING,
  dataType: dataType,
  value: {
    dataSource,
    itemId
  }
});

export default {
  addData,
  setData,
  setError
};
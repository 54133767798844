import React, { useRef, useState } from 'react';
import { LeadingActions, SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import styled from 'styled-components';
import VKUserPanel from './components/VKUserPanel';
import AttentionIcon from "@/content/icons/attention.svg";
import GarbageIcon from "@/content/icons/garbage.svg";
import Api, { HttpMethods } from '@/services/api/api';
import Button from '@/uikit/button';
import { UIDirection } from '@/uikit/constants/UIConstants';
import Panel from '@/uikit/panel';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';
import 'react-swipeable-list/dist/styles.css';

const VKPageBody = styled(Stack)`
  min-height: 200px;  
  margin-bottom: 30px;
  select {
    padding: 10px;
  }
`;

const VKDeletePanel = styled.div`
  line-height: 45px;
  width: 50px;
  background: #e84454;
  padding: 10px;
  border: 1px solid #bb2626;
  margin: 2px 10px;
  text-align: center;
  border-radius: 5px;
  
  svg {
    width: 44px;
    fill: white;
  }
`;

const AttentionStack = styled(Stack)`
  border: 3px dotted #0AD1C9;
  border-radius: 5px;
  padding: 10px;
  
  svg {
    margin: auto;
  }
`;

const VKFindFriends = props => {

  const [step, setStep] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  const [newFriends, setNewFriends] = useState();
  const [addFriends, setAddFriends] = useState();
  const selectGroupId = useRef();

  const LoadFriendsRequest = () => {
    props.isLoading(true);
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/friends/get',
      action: () => {
        props.isLoading(false);
      }
    }).then(response => {
      if (response.result) {
        setFriendsCount(response.friends);
        setStep(1);
      } else {
        setStep(0);
      }
    })
  }

  const LoadFriends = () => {
    return (
      <VKPageBody>
        <Title>Шаг 1</Title>
        <TextBox>Для начала необходимо загрузить список ваших друзей из вк. Это нужно для того, чтобы повторно не пытаться отправить им запрос на добавление в друзья.</TextBox>
        <Button onClick={LoadFriendsRequest}>Загрузить</Button>
      </VKPageBody>
    )
  }

  const prepareFriendListInvite = () => {
    props.isLoading(true);
    Api.makeRequest({
      method: HttpMethods.POST,
      url: 'vk/friends/prepare',
      params: { data: { group_id: selectGroupId.current.value } },
      action: () => {
        props.isLoading(false);
      }
    }).then(response => {
      if (response.result) {
        setNewFriends(response.vkUsers);
        setStep(2);
      } else {
        setStep(1);
      }
    })
  }

  const SelectFriendGroup = () => {
    return (
      <VKPageBody>
        <Title>Шаг 2</Title>
        <Stack gap="small">
          <TextBox>Отлично! В друзьях сейчас {friendsCount} человек.</TextBox>
          <TextBox>Теперь необходимо выбрать группу людей, которым будет отправлен запрос на добавление в друзья.</TextBox>
        </Stack>
        <select name="group" ref={selectGroupId}>
          <option value="1">Тропарево-Никулино</option>
          <option value="2">Проспект Вернадского</option>
          <option value="3">Раменки</option>
          <option value="65535">TEST:Кандидаты в МГД от Новых Людей</option>
        </select>
        <Button onClick={prepareFriendListInvite}>Выбрать</Button>
      </VKPageBody>
    )
  }

  const addFriendsRequest = () => {
    props.isLoading(true);
    Api.makeRequest({
      method: HttpMethods.GET,
      url: 'vk/friends/add',
      action: () => {
        props.isLoading(false);
      }
    }).then(response => {
      if (response.result) {
        setAddFriends(response.count);
        setStep(3);
      } else {
        setStep(2);
      }
    })
  }

  const addBlackListRequest = (id) => {
    Api.makeRequest({
      method: HttpMethods.POST,
      url: 'vk/blacklist/add',
      params: {
        data: {
          vk_id: id,
        }
      },
    })
  }

  const NewFriendsList = () => {

    const trailingActions = (id) => (
      <TrailingActions>
        <SwipeAction destructive={true} onClick={() => addBlackListRequest(id)}>
          <VKDeletePanel>
            <GarbageIcon />
          </VKDeletePanel>
        </SwipeAction>
      </TrailingActions>
    );

    const requestList = newFriends && newFriends.map((vkUser) => {
      return (
        <SwipeableListItem
          trailingActions={trailingActions(vkUser.vk_id)}
        >
          <VKUserPanel {...vkUser} />
        </SwipeableListItem>
      );
    });

    return (
      <VKPageBody>
        <Title>Шаг 3</Title>
        <Stack gap="small">
          <TextBox>Отлично! Список пользователей готов. Осталось отправить им запрос на добавление в друзья.</TextBox>
        </Stack>
        <AttentionStack direction={UIDirection.Row} gap="small">
          <AttentionIcon style={{ fill: '#e84454', height: '36px'}}/>
          <TextBox>Если Вы не хотите отправлять пользователю запрос на добавление в друзья, достаточно смахнуть его влево в списке ниже.</TextBox>
        </AttentionStack>
        <SwipeableList type={ListType.ANDROID} style={{ display: "inline-table"}}>
          {requestList}
        </SwipeableList>
        <Button onClick={addFriendsRequest}>Добавить в друзья</Button>
      </VKPageBody>
    )
  }

  const LastStep = () => {
    return (
      <VKPageBody>
        <Title>Готово</Title>
        <Stack gap="small">
          <TextBox>Отлично! В фоновом режиме уже начинают отправляться запросы в друзья. Их количество видно на главной странице. </TextBox>
          <TextBox>Можно проверять ;)</TextBox>
        </Stack>
      </VKPageBody>
    )
  }
  
  const page = () => {
    switch (step) {
      case 0:
        return <LoadFriends />;
        break;
      case 1:
        return <SelectFriendGroup />;
        break;
      case 2:
        return <NewFriendsList />;
        break;
      case 3:
        return <LastStep />;
        break;
      default:
        return <div>Ошибка</div>;
    }
  }
  
  return (
    <Panel>
      {page()}
    </Panel>
    
  )

}

export default VKFindFriends;
import React, { useState } from 'react';

import styled from 'styled-components';
import OfficeItem from '@/pages/office/OfficeItem';
import Box from '@/uikit/box';
import Button from '@/uikit/button';
import UIConstants, { UIColor } from '@/uikit/constants/UIConstants';
import { BottomDialog } from '@/uikit/dialog';
import Mark from '@/uikit/mark';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';
import Title from '@/uikit/title';

const RadioBox = styled.div`

  position: relative;
  display: block;

  label {
    display: block;
    color: ${props => props.theme.colorMap[UIColor.Grey]};
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colorMap[UIColor.Light]};

    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 11px;
      top: 11px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }

    &:before {
      background: transparent;
      transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s, 0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
      z-index: 2;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: center;
      width: 0;
      height: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
    }
  }
  input {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;

    &:checked + label {
      animation-name: blink;
      animation-duration: 1s;
      border-color: ${props => props.theme.colorMap[UIColor.Main]};

      &:after {
        background: ${props => props.theme.colorMap[UIColor.Main]};
      }

      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }
`

const AnswerBlock = ({ answer }) => {
  let i = 0;
  return answer.map(text => {
    i++;
    return (
      <RadioBox>
        <input id={"answer_"+i} type="radio" name="answer" value={text} />
        <label htmlFor={"answer_"+i}>{text}</label>
      </RadioBox>
    )
  });
}

const PollItem = ({ title, info, answer }) => {

  const [open, setOpen] = useState(false);
  
  return (
    <Box>
      <OfficeItem type="icon" iconName="sun">
        <Stack width={UIConstants.UIWidth.Full} justifyContent="flex-start" gap="small" onClick={setOpen}>
          <TextBox
            align={UIConstants.UIAlign.Left}
            color={UIConstants.UIColor.Dark}
            indent={UIConstants.UIIndent.None}
            size={UIConstants.UISize.Small}
            weight={UIConstants.UIWeight.Encircle}>{title}
          </TextBox>
          <Mark>до 28.04</Mark>
        </Stack>
      </OfficeItem>
      <BottomDialog
        close={() => setOpen(false)}
        icon={"sun"}
        open={open}
        minsize>
        <Stack>
          <Title>{title}</Title>
          <TextBox>{info}</TextBox>
          <Title
            size={UIConstants.UISize.Small}
          >Варианты</Title>
          <form>
            <Stack>
              <AnswerBlock answer={answer}/>
              <Button onClick={() => setOpen(false)}>Завершить</Button>
            </Stack>
          </form>
        </Stack>
      </BottomDialog>
    </Box>
  );
}

export default PollItem;
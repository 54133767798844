import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TextInput from '@/components/Input/TextInput';
import DataConstants from '@/constants/DataConstants';
import DataType from '@/constants/DataType';
import Api, { HttpMethods } from '@/services/api/api';
import RouterUtils from '@/services/routerUtils';
import Button from '@/uikit/button';
import Page from '@/uikit/page';
import Stack from '@/uikit/stack';
import Title from '@/uikit/title';

const Profile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const saveData = formValues => {
    Api.makeRequest({
      method: HttpMethods.POST,
      url: 'userprofile/save',
      params: {
        data: {...formValues}
      }
    }).then(() => {
      navigate('/office');
    });
  }

  return (
    <Page dataSource={DataConstants.USER_PROFILE} dataType={DataType.PAGE}>
      <form>
        <Stack>
          <Title>Данные пользователя</Title>
          <TextInput placeholder="Фамилия" field={register('second_name')} />
          <TextInput placeholder="Имя" field={register('name')} />
          <TextInput placeholder="Отчество" field={register('last_name')} />
          <Button onClick={handleSubmit(saveData)}>Сохранить</Button>
        </Stack>
      </form>
    </Page>
  );

}
function mapStateToProps(state) {

  const router = RouterUtils.getRouterInfo(['news', 'id']);
  const newsId = router.params.id;

  const newsItems = state.data?.newsItems;

  return {
    ...state,
    item: newsItems && newsItems[newsId]?.data,
  };
}

export default connect(mapStateToProps)(Profile);

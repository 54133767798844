import React, { useState } from 'react';

import OfficeItem from '@/pages/office/OfficeItem';
import UIConstants from '@/uikit/constants/UIConstants';
import { BottomDialog } from '@/uikit/dialog';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';

const CalendarItem = () => {

  const [open, setOpen] = useState(false);
  
  return (
    <OfficeItem type="calendar" date="2012-01-26">
      <Stack width={UIConstants.UIWidth.Full} justifyContent="flex-start" gap="non" onClick={setOpen}>
        <TextBox
          align={UIConstants.UIAlign.Left}
          color={UIConstants.UIColor.Dark}
          indent={UIConstants.UIIndent.None}
          size={UIConstants.UISize.Small}
          weight={UIConstants.UIWeight.Encircle}>Заседание транспортного комитета
        </TextBox>
        <TextBox
          align={UIConstants.UIAlign.Left}
          color={UIConstants.UIColor.Dark}
          indent={UIConstants.UIIndent.None}
          size={UIConstants.UISize.Small}>19:00 - 20:30
        </TextBox>
        <BottomDialog close={() => setOpen(false)} open={open}>
          My awesome content here<br />
        </BottomDialog>
      </Stack>
    </OfficeItem>
  );
}

export default CalendarItem;
const NONE = 'NONE';
const SLOW_HIDE = 'SLOW_HIDE';
const SLOW_SHOW = 'SLOW_SHOW';

const LoadingScreenConstants = {
  NONE,
  SLOW_HIDE,
  SLOW_SHOW,
}

export default LoadingScreenConstants;
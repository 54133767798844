import React, { forwardRef } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import styled from 'styled-components';
import UIConstants, { UISize } from '@/uikit/constants/UIConstants';
import Icon from '@/uikit/icon';
import HeadContainer from '@/uikit/icon/HeadContainer';

const Container = styled.div`
  padding: 20px;
`;

const IconContainer = styled.div`
  padding: 50px 20px 20px;
`;

export const BottomDialog = forwardRef(({ close, open, icon, header, minsize, children, ...rest }, ref) => {

  let points = [];

  if (!minsize) {
    points = { snapPoints: ({ maxHeight }) => [
        maxHeight / 2,
        maxHeight / 3 * 2,
      ]};
  }

  if (icon) {
    const headIcon = (
      <HeadContainer
        background={UIConstants.UIColor.Main}
        border={UIConstants.UIColor.White}
        size={UISize.Big}
        centred>
        <Icon path={icon} size={UISize.Big} />
      </HeadContainer>
    );

    return (
      <BottomSheet
        header={icon && headIcon}
        onDismiss={close}
        open={open}
        {...points}>
        <IconContainer>
          { children }
        </IconContainer>
      </BottomSheet>
    );
  }


  return (
    <BottomSheet
      header={header}
      onDismiss={close}
      open={open}
      {...points}>
      <Container>
        { children }
      </Container>
    </BottomSheet>
  );
});
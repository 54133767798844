import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import UIConstants from '@/uikit/constants/UIConstants';

const MainContainerRoot = styled.div(({centred}) => css`
  ${centred &&
  css`
    margin: 0 auto;
  `}
`);

const RoundContainerRoot = styled.div`
  border: 1px solid ${props => props.theme.colorMap[props.border]};
  background-color: ${props => props.theme.colorMap[props.background]};
  border-radius: 25px;
  padding: 10px;
  height: 30px;
  width: 30px;
  ${props => props.right &&
  css`
    position: absolute;
    right: 0px;
  `}
`;

const RoundContainer = forwardRef(({ background, border, children, ...rest }, ref) => {
  return (
    <MainContainerRoot ref={ref} {...rest}>
      <RoundContainerRoot background={background} border={border} right={rest.right}>
        {children}
      </RoundContainerRoot>
    </MainContainerRoot>
  );
});

RoundContainer.propTypes = {
  border: PropTypes.oneOf(UIConstants.UIColor),
  background: PropTypes.oneOf(UIConstants.UIColor),
  size: PropTypes.oneOf(UIConstants.UISize),
}

RoundContainer.defaultProps = {
  border: UIConstants.UIColor.Main,
  background: UIConstants.UIColor.White,
  size: UIConstants.UISize.Default,
};

export default RoundContainer;

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const TextRoot = styled.span`
  color :${props => props.styleType === 'error' ? props.theme.colorBook.errorText : props.theme.colorBook.darkText};
  
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: ${props => props.align};
`;

const TextBox = forwardRef(({ children, ...rest }, ref) => {
  return (
    <TextRoot ref={ref} {...rest}>
      {children}
    </TextRoot>
  );
});

TextBox.propTypes = {
  align: PropTypes.oneOf(['justify', 'center']),
  styleType: PropTypes.oneOf(['default', 'error']),
}

TextBox.defaultProps = {
  align: 'justify',
  styleType: 'default',
};

export default TextBox;

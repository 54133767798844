
const save = (id, data) => {
  try {
    if (window.localStorage) {
      window.localStorage.setItem(id, JSON.stringify(data));
    }
    return true;
  } catch (e) {
    return false;
  }
}

const load = (id) => {
  let res = '';
  if (window.localStorage) {
    const dataFromStorage = window.localStorage.getItem(id);
    res = dataFromStorage;
    try {
      const objectValue = JSON.parse(dataFromStorage);
      res = objectValue;
    } finally {

    }
  }
  return res;
}

const Saver = {
  save,
  load
}

export default Saver;
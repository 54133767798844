import React from 'react';
import { connect } from 'react-redux';

import DataConstants from '@/constants/DataConstants';
import DataType from '@/constants/DataType';
import DataRender from '@/services/dataRender';
import Page from '@/uikit/page';
import Stack from '@/uikit/stack';

class Contacts extends React.Component {

  render() {
    const { page = {} } = this.props;
    return(
      <Page dataSource={DataConstants.CONTACTS} dataType={DataType.PAGE}>
        <Stack>
          {DataRender.titleRender(page)}
          {DataRender.dataRender(page.data)}
        </Stack>
      </Page>
    );
  }

}
function mapStateToProps(state) {
  const page = state.data.contacts;
  return {
    ...state,
    page: page && page.data,
  };
}

export default connect(mapStateToProps)(Contacts);
import DataConfigs from '@/configs/DataConfigs';
import DataType from '@/constants/DataType';
import FetchConstants from '@/constants/FetchConstants';

const _getInitialState = () => {
  const initialState = {};

  for (const key of Object.keys(DataConfigs)) {
    initialState[key] = [];
  }

  return initialState;
};

const dataReducer = (state = _getInitialState(), action) => {
  const actionValue = action.value;
  const chunkStoreKey = actionValue?.dataSource && DataConfigs[actionValue.dataSource].storeKey;

  switch (action.type) {
    case "DATA_ACTION_SET":
      switch (action.dataType) {
        case DataType.LIST:
          if (action.dataStatus === FetchConstants.ERROR_LOADING) {
            state[chunkStoreKey] = {
              error: FetchConstants.ERROR_LOADING
            };
          } else if (action.dataStatus === FetchConstants.READY) {
            state[chunkStoreKey] = [];
            state[chunkStoreKey].push({
              data: actionValue.data,
              status: action.dataStatus
            });
          }
          break;
        case DataType.ITEM:
          if (action.dataStatus === FetchConstants.ERROR_LOADING) {
            state[chunkStoreKey] = {
              error: FetchConstants.ERROR_LOADING
            };
          } else if (action.dataStatus === FetchConstants.READY) {
            state[chunkStoreKey][actionValue.itemId] = {
              data: actionValue.data,
              status: action.dataStatus
            };
          }
          break;
        case DataType.PAGE:
          if (action.dataStatus === FetchConstants.ERROR_LOADING) {
            state[chunkStoreKey] = {
              error: FetchConstants.ERROR_LOADING
            };
          } else if (action.dataStatus === FetchConstants.READY) {
            state[chunkStoreKey] = {
              data: actionValue.data,
              status: action.dataStatus
            };
          }
          break;
      }
      return {
        ...state,
      };
    case "DATA_ACTION_ADD":
      const newState = [];
      newState[chunkStoreKey] = state[chunkStoreKey];

      switch (action.dataType) {
        case DataType.LIST:
          if (action.dataStatus === FetchConstants.ERROR_LOADING) {
            state[chunkStoreKey] = {
              error: FetchConstants.ERROR_LOADING
            };
          } else if (action.dataStatus === FetchConstants.READY) {
            state[chunkStoreKey].push({
              data: actionValue.data,
              status: action.dataStatus
            });
          }
          break;
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};


export default dataReducer;

import React from 'react';
import styled from 'styled-components';

import UIConstants from '@/uikit/constants/UIConstants';
import Icon from '@/uikit/icon';
import RoundContainer from '@/uikit/icon/RoundContainer';
import Stack from '@/uikit/stack';
import TextBox from '@/uikit/textBox';

const LeftBlockRoot = styled.div`
  min-width: 60px;
`;

const CalendarBox = styled.div`
  width: 60px;
  height: 90px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
  border: 1px solid #f0f0f0;
`;

const Separator = styled.hr`
  width: 75%;
  height: 1px;
  background-color: #0AD1C9;
  border: unset;
`;

const LeftBlock = ({ type, iconName, date }) => {

  if (type === 'icon') {
    return (
      <LeftBlockRoot>
        <RoundContainer
          background={UIConstants.UIColor.Main}
          border={UIConstants.UIColor.White}
          centred>
          <Icon path={iconName} />
        </RoundContainer>
      </LeftBlockRoot>
    )
  }

  return (
    <LeftBlockRoot>
      <CalendarBox>
        <Stack gap="non" justifyContent="center">
          <TextBox
            align={UIConstants.UIAlign.Center}
            color={UIConstants.UIColor.Main}
            indent={UIConstants.UIIndent.None}
            size={UIConstants.UISize.Big}>24</TextBox>
          <Separator />
          <TextBox
            align={UIConstants.UIAlign.Center}
            indent={UIConstants.UIIndent.None}
            color={UIConstants.UIColor.Main}
            size={UIConstants.UISize.Big}>04</TextBox>
        </Stack>
      </CalendarBox>
    </LeftBlockRoot>
  );
};

const OfficeItem = ({ children, ...props}) => {
  return (
    <Stack direction={UIConstants.UIDirection.Row}>
      <LeftBlock {...props} />
      { children }
    </Stack>
  );
}

export default OfficeItem;

import React, { forwardRef } from 'react';
import styled from 'styled-components';

const MainContainerRoot = styled.div`
  height: 50px;
  width: 75px;
  margin: auto;
`;

const BoxContainer = forwardRef(({ children, ...rest }, ref) => {
  return (
    <MainContainerRoot ref={ref} {...rest}>
      {children}
    </MainContainerRoot>
  );
});

export default BoxContainer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kameta-phone-input {
  width: 250px;
  height: 50px;
  font-size: 20px;
  color: #515151;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  outline: 0;
  margin: 10px auto;
  text-align: center;
}
.kameta-code-input .sms-code-input-label {
  width: 100%;
  display: block;
  height: 70px;
}
.kameta-code-input input {
  opacity: 0;
  position: absolute;
  top: 0px;
  display: block;
  height: 70px;
}
.kameta-code-input .sms-code {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  vertical-align: top;
  border: 1px solid #e3e3e3;
  line-height: 50px;
  font-size: 2rem;
}
`, "",{"version":3,"sources":["webpack://./components/Input/style/style.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,2CAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAEA;EAGI,WAAA;EACA,cAAA;EACA,YAAA;AAFJ;AAHA;EASI,UAAA;EACA,kBAAA;EACA,QAAA;EACA,cAAA;EACA,YAAA;AAHJ;AAVA;EAiBI,qBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;AAJJ","sourcesContent":[".kameta-phone-input {\n  width: 250px;\n  height: 50px;\n  font-size: 20px;\n  color: #515151;\n  border-radius: 10px;\n  border: 1px solid #e7e7e7;\n  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);\n  outline: 0;\n  margin: 10px auto;\n  text-align: center;\n}\n\n.kameta-code-input {\n\n  .sms-code-input-label {\n    width: 100%;\n    display: block;\n    height: 70px;\n  }\n\n  input {\n    opacity: 0;\n    position: absolute;\n    top: 0px;\n    display: block;\n    height: 70px;\n  }\n\n  .sms-code {\n    display: inline-block;\n    height: 50px;\n    width: 50px;\n    margin: 5px;\n    vertical-align: top;\n    border: 1px solid #e3e3e3;\n    line-height: 50px;\n    font-size: 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { forwardRef } from 'react';
import styled from 'styled-components';

const SvgIconRoot = styled.div`
  height: 24px;
  width: 24px;
  display: block;
  margin: auto;
`

const SvgIcon = forwardRef(({ background, border, children, ...rest }, ref) => {
  return (
    <SvgIconRoot ref={ref} {...rest}>
      {children}
    </SvgIconRoot>
  );
});

export default SvgIcon;
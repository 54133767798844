import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { UIColor } from '@/uikit/constants/UIConstants';

const ButtonRoot = styled.a`
  display: block;
  background: ${props => props.theme.colorMap[props.color]};
  color: ${props => props.theme.colorBook.lightText};
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  transition-duration: 0.4s;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colorMap[UIColor.White]};
  border-radius: 5px;
  
  &:after {
    content: "";
    background: ${props => props.theme.colorBook.background};
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
  }
  
  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }
  
  &:focus { outline:0; }
`;

// TODO: Добавить анимацию для submit
// https://codepen.io/valentingalmand/pen/MYMZZK
const Button = forwardRef(({ children, ...rest }, ref) => {
  return (
    <ButtonRoot ref={ref} {...rest}>
      {children}
    </ButtonRoot>
  );
});

Button.propTypes = {
  color: PropTypes.oneOf(UIColor),
}

Button.defaultProps = {
  color: UIColor.Main,
};

export default Button;

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Bag from "@/content/icons/bag.svg";
import Blocks from "@/content/icons/blocks.svg";
import Books from "@/content/icons/books.svg";
import Briefcase from "@/content/icons/briefcase.svg";
import Calendar from "@/content/icons/calendar.svg";
import Calendar2color from "@/content/icons/calendar2color.svg";
import Chess from "@/content/icons/chess.svg";
import Click from "@/content/icons/click.svg";
import Doctor from "@/content/icons/doctor.svg";
import Exit from "@/content/icons/exit.svg";
import Gardener from "@/content/icons/gardener.svg";
import Home from "@/content/icons/home.svg";
import HouseHeart from "@/content/icons/househeart.svg";
import Lightning from "@/content/icons/lightning.svg";
import Loading from '@/content/icons/loading.svg';
import Manufacture from "@/content/icons/manufacture.svg";
import Menu from "@/content/icons/menu.svg";
import Person from "@/content/icons/person.svg";
import Science from "@/content/icons/science.svg";
import SixPoints from "@/content/icons/sixpoints.svg";
import Sun from "@/content/icons/sun.svg";
import Tower from "@/content/icons/tower.svg";
import Variety from "@/content/icons/variety.svg";
import Vote from "@/content/icons/vote.svg";
import { UISize } from '@/uikit/constants/UIConstants';

const IconRoot = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImgRoot = styled.img`
  height: ${props => props.theme.components.icon.size[props.size]};
  width: ${props => props.theme.components.icon.size[props.size]};
  margin: auto;
`;

const Icon = forwardRef(({ path, ...rest }, ref) => {
  let IconObject;

  //TODO: вынести блок switch из uikit
  switch(path) {
    case 'bag':
      IconObject = Bag;
      break;
    case 'blocks':
      IconObject = Blocks;
      break;
    case 'books':
      IconObject = Books;
      break;
    case 'briefcase':
      IconObject = Briefcase;
      break;
    case 'calendar':
      IconObject = Calendar;
      break;
    case 'calendar2color':
      IconObject = Calendar2color;
      break;
    case 'chess':
      IconObject = Chess;
      break;
    case 'click':
      IconObject = Click;
      break;
    case 'exit':
      IconObject = Exit;
      break;
    case 'doctor':
      IconObject = Doctor;
      break;
    case 'gardener':
      IconObject = Gardener;
      break;
    case 'home':
      IconObject = Home;
      break;
    case 'househeart':
      IconObject = HouseHeart;
      break;
    case 'lightning':
      IconObject = Lightning;
      break;
    case 'loading':
      IconObject = Loading;
      break;
    case 'manufacture':
      IconObject = Manufacture;
      break;
    case 'menu':
      IconObject = Menu;
      break;
    case 'person':
      IconObject = Person;
      break;
    case 'science':
      IconObject = Science;
      break;
    case 'sixpoints':
      IconObject = SixPoints;
      break;
    case 'sun':
      IconObject = Sun;
      break;
    case 'tower':
      IconObject = Tower;
      break;
    case 'variety':
      IconObject = Variety;
      break;
    case 'vote':
      IconObject = Vote;
      break;
    default:
      break;
  }
  if (IconObject) {
    return (
      <IconRoot ref={ref} {...rest}>
        <IconObject {...rest} />
      </IconRoot>
    );
  }

  return (
    <IconRoot ref={ref} {...rest}>
      <ImgRoot src={path} {...rest} />
    </IconRoot>
  );


});

Icon.propTypes = {
  size: PropTypes.oneOf(UISize),
  container: PropTypes.oneOf(['round']),
}

Icon.defaultProps = {
  size: UISize.Default,
  container: 'round'
};

export default Icon;

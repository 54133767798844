import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import AsyncLoader from '@/components/system/AsyncLoader';
import UIConstants, { UIPadding } from '@/uikit/constants/UIConstants';

const PageRoot = styled.div`
  display: block;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
}
`;

const PageContentRoot = styled.div`
  padding: ${props => props.theme.components.page.padding[props.padding]};
`;

const Page = forwardRef((props, ref) => {
  return (
    <PageRoot>
      <AsyncLoader {...props}>
        <PageContentRoot padding={props.padding}>
          {props.children}
        </PageContentRoot>
      </AsyncLoader>
    </PageRoot>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.oneOf(UIConstants.UIDirection),
}

Page.defaultProps = {
  padding: UIPadding.Default,
};

export default Page;

import * as VKID from '@vkid/sdk';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Preloader from '@/components/Preloader';
import VKFindFriends from '@/components/vk';
import Quasar from '@/content/image/quasar.png'
import Api, { HttpMethods } from '@/services/api/api';
import Session from '@/services/Session';
import Stack from '@/uikit/stack';

const PageContentRoot = styled(Stack)`
  max-width: 800px;
  margin: 0px auto;
  height: 100%;
  
  #projectLogo {
    margin: 30px auto;
    max-width: 300px;
    display: block;
  }
  #VkIdSdkOneTap {
    margin: 0 auto;
    max-width: 450px;
    width: 100%;
  }
`;

const MainRoot = styled.div`
  padding: 0px 25px;
`

const Main = () => {

  const [push, setPushData] = useState({});

  const test_push = () => {

      // Для простоты будем использовать кнопку.
      // На боевой версии лучше использовать события.
      var subscriptionButton = document.getElementById('subscriptionButton');

      // Поскольку объект подписки требуется в нескольких местах, давайте создадим метод,
      // который возвращает Promise.
      function getSubscription() {
        return navigator.serviceWorker.ready
          .then(function(registration) {
            return registration.pushManager.getSubscription();
          });
      }

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('bonjour-service-worker.js')
          .then(function() {
            console.log('SW registered');
            subscriptionButton.removeAttribute('disabled');
          });
        getSubscription()
          .then(function(subscription) {
            if (subscription) {
              console.log('Already invaded', subscription.endpoint);
              setUnsubscribeButton();
            } else {
              setSubscribeButton();
            }
          });
      }

  // Получить «registration» от SW и создать новую
  // подписку с помощью `registration.pushManager.subscribe`.
  // Затем зарегистрировать новую подписку, отправив POST-запрос.
      function subscribe() {
        navigator.serviceWorker.ready.then(function(registration) {
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BATxgBsXj6Y8k_F2WQyTAmGeGVzP7wdvza0SwPKBKdrtIqavApL8niCz3RiHKhvxFmBkDLwSyfZj1zUX1P-n5NI'
          });
        }).then(function(subscription) {
          const subJSObject = JSON.parse(JSON.stringify(subscription));
          const endpoint = subJSObject.endpoint;
          const auth = subJSObject.keys.auth;
          const p256dh = subJSObject.keys.p256dh;

          setPushData({
            endpoint,
            auth,
            p256dh
          })

          return fetch('register', {
            method: 'post',
            headers: {
              'Content-type': 'application/json'
            },
            body: JSON.stringify({
              endpoint: subscription.endpoint
            })
          });
        }).then(setUnsubscribeButton);
      }

  // Получить существующую подписку от SW,
  // отменить подписку (`subscription.unsubscribe ()`) и
  // отменить регистрацию на сервере с помощью POST-запроса
  // для прекращения отправки push-сообщений.
      function unsubscribe() {
        getSubscription().then(function(subscription) {
          return subscription.unsubscribe()
            .then(function() {
              console.log('Unsubscribed', subscription.endpoint);
              return fetch('unregister', {
                method: 'post',
                headers: {
                  'Content-type': 'application/json'
                },
                body: JSON.stringify({
                  endpoint: subscription.endpoint
                })
              });
            });
        }).then(setSubscribeButton);
      }

  // Для демонстрации (или тренировок). Изменяем текст кнопки.
      function setSubscribeButton() {
        subscriptionButton.onclick = subscribe;
        subscriptionButton.textContent = 'Open mind!';
      }

      function setUnsubscribeButton() {
        subscriptionButton.onclick = unsubscribe;
        subscriptionButton.textContent = 'Protego!';
      }



  }







































  const [step, setStep] = useState(0);
  const [isLoading, setPreloaderVisibility] = useState(false);
  const oneTap = new VKID.OneTap();

  const button = useRef();

  const handleError = errors => {
    console.log('ERROR', errors);
  }

  const togglePreloader = value => {
    setPreloaderVisibility(value);
  }

  const renderStep = () => {

    switch (step) {
      case 0: {
        return (
          <MainRoot>
            <img id="projectLogo" src={Quasar} />
            <div ref={button} id="VkIdSdkOneTap"></div>
          </MainRoot>
        )
      }
      case 1: {
        return (
          <>
            <VKFindFriends isLoading={togglePreloader} />
          </>
        )
      }
      default:
        return (
          <MainRoot>
            <img id="projectLogo" src={Quasar} />
            <div ref={button} id="VkIdSdkOneTap"></div>
          </MainRoot>
        )
    }
  }

  const generateId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  useEffect(() => {
    if (button.current) {
      // Отрисовка кнопки в контейнере с именем приложения APP_NAME, светлой темой и на русском языке.
      oneTap.render({
        container: button.current,
        scheme: VKID.Scheme.LIGHT,
        lang: VKID.Languages.RUS,
        showAlternativeLogin: true,
      }).on(VKID.WidgetEvents.ERROR, handleError); // handleError — какой-либо обработчик ошибки.

      // Отрисовка шторки в контейнере с именем приложения APP_NAME, светлой темой и на русском языке.
      //floatingOneTap.render({ appName: "Bonjour", scheme: VKID.Scheme.LIGHT, lang: VKID.Languages.RUS }).on(VKID.WidgetEvents.ERROR, handleError); // handleError — какой-либо обработчик ошибки.
    }
  }, [button]);

  VKID.Config.init({
    app: 52052734, // Идентификатор приложения.
    redirectUrl: 'https://kameta.studio', // Адрес для перехода после авторизации.
    state: 'dj29fnsadjsd82', // Произвольная строка состояния приложения.
    codeVerifier: 'dF6u5aeJNaZtaCQCldgiR0NHfQKsFtrzTpqznOiMEQR4JRzDbMBLJm3lpr4JNI8K', // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
    scope: 'friends',
    mode: VKID.ConfigAuthMode.Redirect // По умолчанию авторизация открывается в новой вкладке.
  });

  let [searchParams, setSearchParams] = useSearchParams(window.location.search); // React Hook
  const code = searchParams.get("code");
  const device_id = searchParams.get("device_id");
  const state = searchParams.get("state");

  useEffect(() => {
    if (code && device_id && state) {
      Api.makeRequest({
        method: HttpMethods.POST,
        url: 'login/vk',
        params: { data: {
            code: code,
            device_id: device_id,
            state: state,
          } }
      }).then(response => {
        if (response.access_token) {
          Api.saveToken(response.access_token);
          setStep(1);
        } else {
          setStep(0);
        }
      })
    }
  }, [code, device_id, state]);

  useEffect(() => {
    const session = Session.getToken();
    if (session) {
      if (step === 0) {
        setStep(1)
      }
    }
  }, [])

  /*
  const pwaMessageChannel = new MessageChannel();

  const pwaSendInit = () => {
    navigator.serviceWorker.controller.postMessage(
      {
        type: 'INIT_PORT',
      },
      [pwaMessageChannel.port2],
    );
  };

  navigator.serviceWorker.ready.then(registration => {
    pwaSendInit();

    pwaMessageChannel.port1.onmessage = event => {

      alert('onmessage');

      if (event.data && event.data.type === 'RECEIVED_PUSH') {
        console.log('RECEIVED_PUSH', event.data);
        alert('RECEIVED_PUSH' + JSON.stringify(event.data));
      }

      if (event.data && event.data.type === 'RECEIVED_PUSH_CLICK') {
        console.log('RECEIVED_PUSH_CLICK', event.data);
        alert('RECEIVED_PUSH_CLICK'+JSON.stringify(event.data));
      }
    }
  });*/
// https://web-push-codelab.glitch.me/

  return(
    <>
      <Preloader isVisible={isLoading}/>
      <PageContentRoot>
        {renderStep()}
      </PageContentRoot>

    </>
  );

  /*

        endpoint: {push.endpoint} <br />
      auth: {push.auth} <br />
      p256dh: {push.p256dh} <br />
      <Button id="subscriptionButton" onClick={test_push}>Click</Button>

   */
}

export default connect()(Main);